<template>
  <div>
    <b-card no-body class="mb-0">

      <div class="m-2">

        <!-- Table Top -->
        <b-row>

          <!-- Per Page -->
          <b-col cols="12" md="6" class="d-flex align-items-center justify-content-start mb-1 mb-md-0">
            <label>显示</label>
            <v-select
                v-model="limit"
                :options="limitOptions"
                :clearable="false"
                class="per-page-selector d-inline-block mx-50"
            />
            <label>条</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-button
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="outline-primary"
                  class="mr-1"
                  @click="advanced_search"
              >
                <feather-icon
                    icon="SearchIcon"
                    class="mr-50"
                />
                <span class="align-middle">高级搜索</span>
              </b-button>

              <b-button
                  v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                  variant="outline-success"
                  @click="exportExcel"
                  class="mr-1"
              >
                <feather-icon
                    icon="DownloadIcon"
                    class="mr-50"
                />
                <span class="align-middle">导出</span>
              </b-button>
            </div>
          </b-col>
        </b-row>

        <!-- 高级搜索 -->
        <b-card v-if="isShowCard">
          <b-form
              @submit.prevent="searchByCondition"
              @reset.prevent="resetCondition">
            <b-row>
              <!--公司全称-->
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="公司全称"
                    label-for="company_name"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="company_name"
                      size="sm"
                      v-model="condition.company_name"
                  />
                </b-form-group>
              </b-col>
              <!--公司简称-->
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="公司简称"
                    label-for="nick_name"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="nick_name"
                      size="sm"
                      v-model="condition.nick_name"
                  />
                </b-form-group>
              </b-col>
              <!--统一社会信用代码-->
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="统一社会信用代码"
                    label-for="uniform_social_credit_code"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="uniform_social_credit_code"
                      size="sm"
                      v-model="condition.uniform_social_credit_code"
                  />
                </b-form-group>
              </b-col>
              <!--公司id-->
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="公司ONEID"
                    label-for="one_company_id"
                    label-size="sm"
                    class="mb-1"
                >
                  <b-form-input
                      id="one_company_id"
                      size="sm"
                      type="number"
                      v-model="condition.one_company_id"
                  />
                </b-form-group>
              </b-col>
              <!--审核状态-->
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="审核状态"
                    label-for="status"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="status"
                      :options="getCodeOptions('company_status')"
                      v-model="condition.status"
                      class="select-size-sm"
                      placeholder="请选择审核状态"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <!--启用-->
              <b-col md="4">
                <b-form-group
                    label-cols="3"
                    label-cols-lg="3"
                    label="启用状态"
                    label-for="state"
                    label-size="sm"
                    class="mb-1"
                >
                  <v-select
                      id="state"
                      :options="getCodeOptions('state')"
                      v-model="condition.state"
                      class="select-size-sm"
                      placeholder="请选择启用状态"
                      :reduce="option => option.value"
                  />
                </b-form-group>
              </b-col>
              <!--查询、重置按钮-->
              <b-col
                  cols="12"
                  md="12"
                  class=" demo-inline-spacing d-flex align-items-center justify-content-end"
              >
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="submit"
                >
                  <span class="align-right">查询</span>
                </b-button>
                <b-button
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                    variant="outline-primary"
                    type="reset"
                >
                  <span class="align-right">重置</span>
                </b-button>
              </b-col>
            </b-row>
          </b-form>
        </b-card>

      </div>


      <b-table
          ref="refListTable"
          class="position-relative"
          :items="searchList"
          responsive
          hover
          small
          :fields="tableColumns"
          primary-key="id"
          :sort-by.sync="orderBy"
          show-empty
          empty-text="未找到记录"
          :sort-desc.sync="isSortDirDesc"
      >

        <!-- Columns -->
        <template #cell(company_id)="data">
          #{{ data.item.company_id }}
        </template>

        <!-- Column: Type -->
        <template #cell(company_properties)="data">
          {{ getCodeLabel('company_properties', data.item.company_properties) }}
        </template>


        <!-- 状态 -->
        <template #cell(status)="data">
          <b-badge
              pill
              :variant="`light-${getCodeColor('company_status', data.item.status)}`"
          >
            {{ getCodeLabel('company_status', data.item.status) }}
          </b-badge>
        </template>

        <!-- 启用 -->
        <template #cell(state)="data">
          <b-link
              @click="changeState(data.item)"
          >
            <b-badge
                pill
                :variant="`light-${getCodeColor('state', data.item.state)}`"
            >
              {{ getCodeLabel('state', data.item.state) }}
            </b-badge>
          </b-link>
        </template>

        <!-- 添加时间       -->
        <template #cell(create_time)="data">
          {{ toTime(data.item.create_time) }}
        </template>

        <!--添加人        -->
        <template #cell(creator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <!-- 更新时间       -->
        <template #cell(update_time)="data">
          {{ toTime(data.item.update_time) }}
        </template>

        <!--更新人        -->
        <template #cell(updator)="{value}">
          {{ getCodeLabel('user', value) }}
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown variant="link" no-caret>

            <template #button-content>
              <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="align-middle text-body"
              />
            </template>

            <b-dropdown-item :to="{ name: 'apps-company-supplier-new-edit', query: { id: data.item.id } }"
                             v-if="data.item.status === 2 || data.item.status === 5 || data.item.status === 3">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>
            <!--审核通过后编辑-->
            <b-dropdown-item :to="{ name: 'apps-company-supplier-new-edit', query: { id: data.item.id,afterAudit:1 } }"
                             v-if="data.item.status === 4">
              <feather-icon icon="EditIcon"/>
              <span class="align-middle ml-50">编辑</span>
            </b-dropdown-item>

            <b-dropdown-item @click="audit(data.item.id,3,'确定提交审核吗？')" v-if="data.item.status === 2">
              <feather-icon icon="FileTextIcon"/>
              <span class="align-middle ml-50">提交审核</span>
            </b-dropdown-item>

            <b-dropdown-item @click="audit(data.item.id,4,'确定审核通过吗？')" v-if="data.item.status === 3">
              <feather-icon icon="CheckIcon"/>
              <span class="align-middle ml-50">审核通过</span>
            </b-dropdown-item>

            <b-dropdown-item @click="audit(data.item.id,5,'确定审核不通过吗？')" v-if="data.item.status === 3">
              <feather-icon icon="XIcon"/>
              <span class="align-middle ml-50">审核不通过</span>
            </b-dropdown-item>


          </b-dropdown>
        </template>


      </b-table>

      <div class="mx-2 mb-2">
        <b-row>

          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">显示 {{ dataMeta.from }} - {{ dataMeta.to }} / {{ dataMeta.of }} 记录</span>
          </b-col>
          <!-- Pagination -->
          <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
                v-model="start"
                :total-rows="listTotals"
                :per-page="limit"
                first-number
                last-number
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>

    </b-card>
  </div>
</template>


<script>
import {
  BCard, BRow, BCol, BFormInput, BButton, BTable, BLink,
  BBadge, BDropdown, BDropdownItem, BPagination,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import store from '@/store'
import {ref, onUnmounted} from '@vue/composition-api'
import {avatarText, toTime, toDate, getCodeOptions, getCode, getCodeLabel, getCodeColor} from '@core/utils/filter'
import customerUseList from './supplierUseList'
import Ripple from "vue-ripple-directive";
import {useToast} from "vue-toastification/composition";
import companyStore from "@/views/apps/company/companyStore";
import axios from '@axios'
import {getUserData} from "@/auth/utils";

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    vSelect,
  },
  data() {
    return {
      isShowCard: false,
      rangeConfig: {
        mode: 'range',
      },
      user: {},
    }
  },
  directives: {
    Ripple,
  },
  setup() {
    const toast = useToast()
    // Register module
    if (!store.hasModule('companystore')) store.registerModule('companystore', companyStore)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule('companystore')) store.unregisterModule('companystore')
    })


    const advanced_search = function () {
      this.isShowCard = !this.isShowCard
    }

    const searchByCondition = function () {
      refetchData()
    }

    const resetCondition = function () {
      this.condition = {}
      refetchData()
    }

    const audit = function (id, status, title, text) {
      this.$swal({
        title: title,
        text: text,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        buttonsStyling: false,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
      }).then(result => {
        if (result.value) {
          axios.post('api/company/supplierAudit', {company_id: id, status: status}).then(res => {
            if (res.data.code == 0) {
              //弹窗
              this.$swal({
                icon: 'success',
                title: '已提交!',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              //重新加载表格
              refetchData()
            } else {
              toast.error(res.data.data)
            }
          })
        }
      })
    }

    const changeState = function (data) {
      if (data.status == 4) {
        const state = 1 - data.state
        axios.post('api/company/oneState', {id: data.id, state: state, type: 2}).then(res => {
          data.state = state
          if (res.data.code == 1) {
            toast.error(res.data.data)
          } else {
            toast.success('更新成功')
          }
          refetchData()
        })
      } else {
        toast.error('请先审核')
      }
    }

    const exportExcel = function () {
      const params = {
        company_type: 3,
        company_name: condition.value.company_name,
        nick_name: condition.value.nick_name,
        uniform_social_credit_code: condition.value.uniform_social_credit_code,
        one_company_id: condition.value.one_company_id,
        status: condition.value.status,
        state: condition.value.state,
      }
      axios({
        url: 'api/exportexcelfile/exportCompany',
        method: 'get',
        params: params,
        responseType: 'blob',
      }).then(res => {
        toast.success('导出成功')
        let data = new Blob([res.data], {type: 'application/vnd.ms-excel'})   //接收的是blob，若接收的是文件流，需要转化一下
        let filename = '供应商' + new Date().getTime()
        /*-------------*/
        const link = document.createElement('a')
        link.style.display = 'none'
        link.href = URL.createObjectURL(data)
        link.setAttribute('download', filename + '.xlsx')
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      })

    }

    const {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      condition,

      // UI
    } = customerUseList()

    return {
      searchList,
      tableColumns,
      limit,
      start,
      listTotals,
      dataMeta,
      limitOptions,
      searchKey,
      orderBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      advanced_search,
      searchByCondition,
      resetCondition,
      condition,
      audit,
      changeState,
      exportExcel,

      // Filter
      avatarText,
      getCodeOptions,
      getCode,
      getCodeLabel,
      getCodeColor,
      // UI
      toTime,
      toDate,
    }
  },
  created() {
    const userData = getUserData()
    this.user = userData
  }
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
